<template>
  <div class="py-3">
    <DataTable @row-click="openDetails" id="activityTable" ref="datatable" :ajax="`${useRuntimeConfig().public.apiURL}/activities`" :order="[0, 'desc']" :additional-data="[{ key: 'type', value: type }, { key: 'id', value: id }]" :columns="dataTableHeader" />
    <Modal ref="activityDetailsModal" title="Details">
      <JsonViewer :data="details" />
    </Modal>
  </div>
</template>

<script setup>
const props = defineProps([ "type", "id" ]);
const datatable = ref(null);
const { format, $repository, globalStore } = useNuxtApp();
const dataTableHeader = [
  { data: "created_at", title: "Datum", render(data) { return format.dateFormat(data, "DD.MM.YY HH:mm:ss"); } },
  { data: "description", title: "Beschreibung", render(data) { return data ? format.longTextSlice(data, 320) : ""; } },
  // { data: "properties", title: "Details", render(data) { return data ? format.longTextSlice(JSON.stringify(data), 40) : ""; } },
  { data: "causer", title: "Von" },
];
const activityDetailsModal = ref({});
const details = ref(null);
async function openDetails(row) {
  globalStore.isLoading = true;
  const request = await $repository.activity.getActivity(row.getAttribute("id"));
  globalStore.isLoading = false;
  if (request && request.success) {
    details.value = request.activity.properties;
    activityDetailsModal.value.open();
  }
}

watch(() => props, () => {
  if (datatable.value) {
    datatable.value.reload();
  }
}, { immediate: true });
</script>
